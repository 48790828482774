<template>
  <div>
    <b-overlay :show="isShowLoading">
      <b-card :title="$t('master.question.form')">

        <validation-observer ref="validateForm" #default="{invalid}">
          <b-form @submit.prevent="saveData">
            <b-row>
              <b-col md="6">
                <b-form-group :label="$t('master.question.type') + '*'">
                  <validation-provider #default="{ errors }" :name="$t('master.question.type')" rules="required">
                    <v-select v-model="questionType" label="typeName"
                              :options="optionQuestionType" :reduce="option => option.typeId"
                              :class="errors.length > 0 ? 'vue-select is-invalid' : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group :label="$t('master.question.name') + ' [TH]*'">
                  <validation-provider #default="{ errors }" :name="$t('master.question.name')" rules="required">
                    <b-form-input v-model="questionNameTh" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group :label="$t('master.question.name') + ' [EN]'">
                  <b-form-input v-model="questionNameEn"/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.question.answer1') + ' [TH]*'">
                  <validation-provider #default="{ errors }" :name="$t('master.question.answer1')" rules="required">
                    <b-form-input v-model="answerNameTh1" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.question.answer1') + ' [EN]'">
                  <b-form-input v-model="answerNameEn1"/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.question.answer2') + ' [TH]*'">
                  <validation-provider #default="{ errors }" :name="$t('master.question.answer2')" rules="required">
                    <b-form-input v-model="answerNameTh2" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.question.answer2') + ' [EN]'">
                  <b-form-input v-model="answerNameEn2"/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.question.answer3') + ' [TH]*'">
                  <validation-provider #default="{ errors }" :name="$t('master.question.answer3')" rules="required">
                    <b-form-input v-model="answerNameTh3" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.question.answer3') + ' [EN]'">
                  <b-form-input v-model="answerNameEn3"/>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group :label="$t('master.question.answer4') + ' [TH]*'">
                  <validation-provider #default="{ errors }" :name="$t('master.question.answer4')" rules="required">
                    <b-form-input v-model="answerNameTh4" :state="errors.length > 0 ? false : null"/>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="false">
                <b-form-group :label="$t('master.question.answer4') + ' [EN]'">
                  <b-form-input v-model="answerNameEn4"/>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group :label="$t('master.question.correctAnswer')">
                  <b-form-radio-group v-model="correctAnswer">
                    <b-form-radio value="1">{{ $t('master.question.answer1') }}</b-form-radio>
                    <b-form-radio value="2">{{ $t('master.question.answer2') }}</b-form-radio>
                    <b-form-radio value="3">{{ $t('master.question.answer3') }}</b-form-radio>
                    <b-form-radio value="4" v-if="false">{{ $t('master.question.answer4') }}</b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col class="text-center">
                <b-button type="submit" variant="success" class="my-1" :disabled="isBtnDisabled">
                  <b-spinner small v-if="isBtnDisabled"/>
                  <feather-icon icon="SaveIcon" v-else/>
                  {{ $t('general.btnSubmit') }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>

      </b-card>
    </b-overlay>
  </div>

</template>

<script>
import {BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BButton, BOverlay, BFormRadioGroup, BFormRadio} from 'bootstrap-vue'
import {required} from '@validations'
import formMixins from "@/mixins/formMixins"
import masterMixins from "@/mixins/masterMixins"
import {QuestionService} from "@/services"

const questionService = new QuestionService()

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    BOverlay,
    BFormRadioGroup,
    BFormRadio,
  },
  mixins: [masterMixins, formMixins],
  async created() {
    this.id = this.$route.params.id

    this.optionQuestionType = await this.listTrainingQuestionType()

    if (this.id) {
      await this.getData()
    }

    this.isShowLoading = false
    this.enabledCheckBackButton()
  },
  data: () => ({
    isShowLoading: true,
    isBtnDisabled: false,
    id: '',
    optionQuestionType: [],
    questionType: '',
    questionNameTh: '',
    questionNameEn: '',
    answerNameTh1: '',
    answerNameEn1: '',
    answerNameTh2: '',
    answerNameEn2: '',
    answerNameTh3: '',
    answerNameEn3: '',
    answerNameTh4: '',
    answerNameEn4: '',
    correctAnswer: '',
  }),
  methods: {
    async getData() {
      let queryResult = await questionService.getData(this.id)
      if (queryResult.isSuccess) {
        let {data} = queryResult
        let {result} = data
        this.questionType = result.questionType
        this.questionNameTh = result.questionNameTh
        this.questionNameEn = result.questionNameEn
        this.answerNameTh1 = result.answerNameTh1
        this.answerNameEn1 = result.answerNameEn1
        this.answerNameTh2 = result.answerNameTh2
        this.answerNameEn2 = result.answerNameEn2
        this.answerNameTh3 = result.answerNameTh3
        this.answerNameEn3 = result.answerNameEn3
        this.answerNameTh4 = result.answerNameTh4
        this.answerNameEn4 = result.answerNameEn4
        this.correctAnswer = result.answerCorrectNumber
      } else {
        this.$store.commit('main/setToastError', queryResult.data)
        await this.$router.push({name: 'pageMasterQuestion'})
      }
    },
    saveData() {
      this.$refs.validateForm.validate().then(async success => {
        if (success) {
          this.isBtnDisabled = true
          let actionData = {
            questionType: this.questionType,
            questionNameTh: this.questionNameTh,
            questionNameEn: this.questionNameEn,
            answerNameTh1: this.answerNameTh1,
            answerNameEn1: this.answerNameEn1,
            answerNameTh2: this.answerNameTh2,
            answerNameEn2: this.answerNameEn2,
            answerNameTh3: this.answerNameTh3,
            answerNameEn3: this.answerNameEn3,
            answerNameTh4: this.answerNameTh4,
            answerNameEn4: this.answerNameEn4,
            correctAnswer: this.correctAnswer,
          }
          let saveResult

          if (this.id) {
            saveResult = await questionService.updateData(this.id, actionData)
          } else {
            saveResult = await questionService.createData(actionData)
          }

          this.isBtnDisabled = false

          if (saveResult.isSuccess) {
            this.$store.commit('main/setToastSuccess', {title: 'Save success', text: ''})
            await this.$router.push({name: 'pageMasterQuestion'})
          } else {
            this.$store.commit('main/setToastError', saveResult.data)
          }
        }
      })
    }
  }
}
</script>

<style>

</style>
