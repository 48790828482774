var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-overlay',{attrs:{"show":_vm.isShowLoading}},[_c('b-card',{attrs:{"title":_vm.$t('master.question.form')}},[_c('validation-observer',{ref:"validateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveData($event)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.type') + '*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.question.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{class:errors.length > 0 ? 'vue-select is-invalid' : null,attrs:{"label":"typeName","options":_vm.optionQuestionType,"reduce":function (option) { return option.typeId; }},model:{value:(_vm.questionType),callback:function ($$v) {_vm.questionType=$$v},expression:"questionType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.name') + ' [TH]*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.question.name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.questionNameTh),callback:function ($$v) {_vm.questionNameTh=$$v},expression:"questionNameTh"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.name') + ' [EN]'}},[_c('b-form-input',{model:{value:(_vm.questionNameEn),callback:function ($$v) {_vm.questionNameEn=$$v},expression:"questionNameEn"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.answer1') + ' [TH]*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.question.answer1'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.answerNameTh1),callback:function ($$v) {_vm.answerNameTh1=$$v},expression:"answerNameTh1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.answer1') + ' [EN]'}},[_c('b-form-input',{model:{value:(_vm.answerNameEn1),callback:function ($$v) {_vm.answerNameEn1=$$v},expression:"answerNameEn1"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.answer2') + ' [TH]*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.question.answer2'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.answerNameTh2),callback:function ($$v) {_vm.answerNameTh2=$$v},expression:"answerNameTh2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.answer2') + ' [EN]'}},[_c('b-form-input',{model:{value:(_vm.answerNameEn2),callback:function ($$v) {_vm.answerNameEn2=$$v},expression:"answerNameEn2"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.answer3') + ' [TH]*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.question.answer3'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.answerNameTh3),callback:function ($$v) {_vm.answerNameTh3=$$v},expression:"answerNameTh3"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.answer3') + ' [EN]'}},[_c('b-form-input',{model:{value:(_vm.answerNameEn3),callback:function ($$v) {_vm.answerNameEn3=$$v},expression:"answerNameEn3"}})],1)],1),(false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.answer4') + ' [TH]*'}},[_c('validation-provider',{attrs:{"name":_vm.$t('master.question.answer4'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.answerNameTh4),callback:function ($$v) {_vm.answerNameTh4=$$v},expression:"answerNameTh4"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1):_vm._e(),(false)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.answer4') + ' [EN]'}},[_c('b-form-input',{model:{value:(_vm.answerNameEn4),callback:function ($$v) {_vm.answerNameEn4=$$v},expression:"answerNameEn4"}})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('master.question.correctAnswer')}},[_c('b-form-radio-group',{model:{value:(_vm.correctAnswer),callback:function ($$v) {_vm.correctAnswer=$$v},expression:"correctAnswer"}},[_c('b-form-radio',{attrs:{"value":"1"}},[_vm._v(_vm._s(_vm.$t('master.question.answer1')))]),_c('b-form-radio',{attrs:{"value":"2"}},[_vm._v(_vm._s(_vm.$t('master.question.answer2')))]),_c('b-form-radio',{attrs:{"value":"3"}},[_vm._v(_vm._s(_vm.$t('master.question.answer3')))]),(false)?_c('b-form-radio',{attrs:{"value":"4"}},[_vm._v(_vm._s(_vm.$t('master.question.answer4')))]):_vm._e()],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"text-center"},[_c('b-button',{staticClass:"my-1",attrs:{"type":"submit","variant":"success","disabled":_vm.isBtnDisabled}},[(_vm.isBtnDisabled)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t('general.btnSubmit'))+" ")],1)],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }